//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _rbacPermission } from '@/api/user'
import { _getshow } from '@/api/message.js'
export default {
  watch: {
    $route(to, from) {
      this.active = JSON.parse(sessionStorage.getItem('active'))
      this.data = JSON.parse(sessionStorage.getItem('dataList'))
      this.selectIndex = JSON.parse(sessionStorage.getItem('selectIndex'))
      this.$emit('getdata', this.data)
    },
  },
  name: 'Sidebar',
  data() {
    return {
      lists: [],
      active: '',
      data: [],
      type: '',
      selectIndex: '',
      images: [],
      isdot: false,
    }
  },
  created() {
    this.lists = JSON.parse(localStorage.getItem('returnA'))
    this.lists.forEach((item) => {
      item.icons = require(`@/assets/img/${item.icon}.png`)
    })
    this.selectIndex = JSON.parse(sessionStorage.getItem('selectIndex'))
      ? JSON.parse(sessionStorage.getItem('selectIndex'))
      : 0
    // 1 shz修改
  },
  mounted() {
    this.type = JSON.parse(localStorage.getItem('registerType'))
    if (JSON.parse(sessionStorage.getItem('active'))) {
      this.active = JSON.parse(sessionStorage.getItem('active'))
      this.data = JSON.parse(sessionStorage.getItem('dataList'))
      this.$emit('getdata', this.data)
    } else {
      this.$emit('getdata', this.lists[0])
      this.active = this.lists[0].active
    }
    _getshow({ current: 1, page: 1 }).then((res) => {
      if (res.data.data[0].complteStatus == 0) {
        this.isdot = true
      } else {
        this.isdot = false
      }
    })
    console.log(this.$route.query.star)
    if (this.$route.query.star) {
      // console.log(JSON.parse(localStorage.getItem('roleType'))  == '1');
      if (JSON.parse(localStorage.getItem('roleType')) == '1') {
        sessionStorage.setItem('active', JSON.stringify('ptstarrating'))

        this.lists.forEach((item, index) => {
          if (item.active == 'ptstarrating') {
            this.selectIndex = index
            console.log(item)
            this.data = item
            this.$emit('getdata', this.data)
            //  sessionStorage.setItem('selectIndex',JSON.stringify('starrating'))
          }
        })
      } else {
        sessionStorage.setItem('active', JSON.stringify('starrating'))

        this.lists.forEach((item, index) => {
          if (item.active == 'starrating') {
            this.selectIndex = index
            console.log(item)
            this.data = item
            this.$emit('getdata', this.data)
            //  sessionStorage.setItem('selectIndex',JSON.stringify('starrating'))
          }
        })
      }
    }
  },
  updated() {
    _getshow({ current: 1, page: 1 }).then((res) => {
      if (res.data.data[0].complteStatus == 0) {
        this.isdot = true
      } else {
        this.isdot = false
      }
    })
  },
  methods: {
    handleSelect(item, index) {
      // 赋值
      this.active = item.active
      // + 1 shz修改
      this.selectIndex = index
      // 跳转路由
      this.lists.filter((item) => {
        if (item.active === this.active) {
          this.data = item
        }
      })
      // 点击的数据传值给父组件
      this.$nextTick(() => {
        this.$emit('getdata', this.data)
      })
      this.$router.push(item.path)
      // 存储active状态值 刷新的时候拿到赋值高亮
      sessionStorage.setItem('active', JSON.stringify(item.active))
      sessionStorage.setItem('dataList', JSON.stringify(this.data))
      sessionStorage.setItem('selectIndex', JSON.stringify(this.selectIndex))
    },
  },
}
