//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Sidebars from "../../components/Sidebar.vue";
import Headers from "../../components/Headers.vue";
export default {
  components: {
    Sidebars,
    Headers,
  },
  data() {
    return {
      datas: [],
    };
  },
  created(){
    let roleType = '5';
    console.log(roleType.indexOf(6) != -1 || roleType.indexOf(7) != -1)
  },
  methods: {
    // 获取子组件的数据
    getdata(data) {
    if(data != null){
    this.datas = data.children;
    }
    },
  },
};
